import { defineStore } from 'pinia';
import { useApi } from '@/composables/api';
import { UserCredentials, UserModel } from '@/types';
import { useAppStore } from './app.store';
import { Login2FaRequest, LoginRequest } from '@/api/services/auth';

interface UserStore {
  token: string;
  user: UserModel;
  credentials: UserCredentials;
  tempQr: string | null;
}

interface AccessList {
  list: boolean;
  view: boolean;
  edit: boolean;
  create: boolean;
  delete: boolean;
  renew: boolean;
}

const storageToken = localStorage.getItem('_token') || '';
const storageUser = localStorage.getItem('user') || null;

export const useUserStore = defineStore('user', {
  state: () =>
    ({
      token: storageToken,
      user: storageUser
        ? (JSON.parse(storageUser) as UserModel)
        : {
            avatar: null,
            email: '',
            id: null,
            last_login_at: '',
            name: '',
            permissions: [],
            table_permissions: [],
            role: '',
            role_id: null,
            is_2fa: null,
          },
      credentials: {},
    } as UserStore),

  getters: {
    // Check is user logged in
    isLoggedIn: (state) => !!state.token,

    // Get user object
    getUser: (state) => state.user,

    // Get user permissions array
    getPermissionsFromUser: (state) => state.user?.permissions,

    // Is user has provided table permission
    getAvailablePermissionsTableFromUser: (state) => (table: string) => {
      const tableWithPermissions = state.user?.table_permissions?.find((perm) => perm.table === table);
      if (!tableWithPermissions) return null;

      return tableWithPermissions.columns.map((column) => column.column_name);
    },

    // Is user has provided table permission
    getPermissionsTableFromUser: (state) => (table: string) => {
      const tableWithPermissions = state.user?.table_permissions?.find((perm) => perm.table === table);
      if (!tableWithPermissions) return null;

      return tableWithPermissions.columns
        .filter((column) => column.status || state.user.role_id === 1)
        .map((column) => column.column_name);
    },

    // Get permissions that includes provided value in name
    getAccess: (state) => (accessName: string) => {
      return state.user.permissions?.filter((permission) => permission?.includes(accessName)) || [];
    },

    // Check if user has provided permission name
    hasPermission: (state) => (permissionName: string) => state.user.permissions?.includes(permissionName),

    // Check if user has permissions that requires 2fa to be setted
    forbiddenBy2fa: (state) =>
      !state.user.is_2fa &&
      state.user?.permissions?.some((permission) => {
        return [
          'wallets.create',
          'wallets.delete',
          'wallet_balance_history.edit',
          'wallet_balance_history.create',
          'wallet_balance_history.delete',
        ].includes(permission);
      }),
  },

  actions: {
    async login(payload: LoginRequest) {
      const { login } = useApi();

      const { data } = await login(payload);
      const user = data.result;

      if (!('access_token' in user) && user.result === '2fa') {
        return {
          status: '2fa',
          payload: user.id,
        };
      }

      this.addTokenToLs((<UserModel>user).access_token);
      this.saveUser(<UserModel>user);

      if (!user.last_login_at) {
        return {
          status: 'novice',
          payload: user.id,
        };
      }

      return { status: 'ok' };
    },

    async login2fa(payload: Login2FaRequest) {
      const { login2fa } = useApi();

      const { data } = await login2fa(payload);
      const user = data.result;

      if (!user.access_token && 'result' in user) return;

      this.addTokenToLs(user.access_token);
      this.saveUser(user);

      if (!user.last_login_at) return { status: 'novice', payload: user.id };

      return { status: 'ok' };
    },

    async logout() {
      const $app = useAppStore();
      try {
        $app.setBusy(true);

        this.removeTokenFromLs();
        this.removeUser();

        // Remove stored headers visibility
        localStorage.removeItem('headers-visibility');

        location.href = '/login';
      } catch (err) {
        console.log('err', err);
      } finally {
        $app.setBusy(false);
      }
    },

    saveUser(user: UserModel) {
      this.user = user;
      localStorage.setItem('user', JSON.stringify(this.user));
    },

    removeUser() {
      this.user = {} as UserModel;
      localStorage.removeItem('user');
    },

    addTokenToLs(token: string) {
      if (this.token) return;
      localStorage.setItem('_token', token);
      this.token = token;
    },

    removeTokenFromLs() {
      localStorage.removeItem('_token');
      this.token = '';
    },

    save2fa(is2fa: boolean) {
      this.user.is_2fa = is2fa;
      localStorage.setItem('user', JSON.stringify(this.user));
    },

    save2faPayout(is2faPayout: boolean) {
      this.user.is_2fa_payout = is2faPayout;
      localStorage.setItem('user', JSON.stringify(this.user));
    },

    saveTempQr(qr: string | null) {
      this.tempQr = qr;
    },
  },
});
